<template>
  <div class="detail-page">
    <div
      class="progress-bar"
      :style="{ width: `${Number(barWidth * 100)}%` }"
    ></div>
    <header>
      <div class="left"><i class="iconfont icon-nicetool"></i></div>
      <div class="center">
        <span class="title" :style="{ opacity: titleShow ? 1 : 0 }">{{
          title.text
        }}</span>
      </div>
      <div class="right"><i class="iconfont icon-nicetool"></i></div>
    </header>
    <div class="header-placeholder"></div>
    <div class="content" id="content">
      <MarkdownPreview id="markdown" :initialValue="content" :theme="theme" />
    </div>
    <div class="footer">
      <a href="http://beian.miit.gov.cn/" target="_blank"
        >鄂ICP备2022003829号</a
      >
    </div>
  </div>
</template>

<script>
import { MarkdownPreview } from "vue-meditor";
import { _GetDetail } from "@/api/detailPage.js";
export default {
  name: "",
  data() {
    return {
      content: "",
      theme: "light",
      title: {
        distance: null,
        text: null,
      },
      titleShow: false,
      contenDistance: null,
      barWidth: 0,
    };
  },
  created() {
    this.getDetail();
  },
  mounted() {},
  methods: {
    // 获取文章详情
    async getDetail() {
      try {
        let res = await _GetDetail({ id: this.$route.params.id });
        if (res.success) {
          this.title.text = res.data.title;
          this.content = res.data.value;
          this.theme = res.data.theme;
        } else {
          this.$message.error("获取文章详情失败，请稍后再试");
        }
      } catch (err) {}
    },

    // 页面滚动
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > this.title.distance) {
        this.titleShow = true;
      } else {
        this.titleShow = false;
      }
      // 进度条
      if (scrollTop / this.contenDistance >= 1) {
        this.barWidth = 1;
      } else {
        this.barWidth = scrollTop / this.contenDistance;
      }
    },
  },
  watch: {
    content() {
      this.$nextTick(() => {
        // 获取文章标题
        let titleDom =
          document.getElementById("markdown").firstElementChild
            .firstElementChild;
        this.title.distance =
          titleDom.offsetTop + titleDom.offsetHeight / 2 - 50;
        // this.title.text = titleDom.innerText;
        // 获取整个文章
        let content = document.getElementById("content");
        this.contenDistance =
          content.offsetTop + content.offsetHeight - window.innerHeight;
        // 添加滚动监听
        window.addEventListener("scroll", this.handleScroll);
      });
    },
  },
  components: {
    MarkdownPreview,
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #50bcb6;
  transition: width 0.5s ease;
  z-index: 999999;
}
header {
  top: 0;
  position: fixed;
  padding: 0 40px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f6f7f8;
  background: #fff;
  z-index: 999;
  box-sizing: border-box;
  .left {
    > i {
      font-size: 68px;
    }
  }
  .right {
    visibility: hidden;
    > i {
      font-size: 68px;
    }
  }
  .title {
    transition: all 1s;
    opacity: 0;
  }
}
.header-placeholder {
  height: 50px;
}
.content {
  width: 800px;
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  header {
    padding: 0;
    justify-content: space-around;
    .left {
      > i {
        font-size: 40px;
      }
    }
    .right {
      visibility: hidden;
      > i {
        font-size: 40px;
      }
    }
  }
  .content {
    width: 100%;
    margin: 0;
  }
}
.footer {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  text-align: center;
  a {
    color: #999;
  }
}
</style>
