import service from "@/utils/request.js";

// 获取文章详情
// export function _GetDetail(data) {
//   return service({
//     url: "http://localhost:3000/system/staff/post/list",
//     method: "get",
//     data,
//   });
// }

// 获取文章详情
export function _GetDetail(params) {
  return service({
    url: "/getArticleDetail",
    method: "get",
    params,
  });
}
